<template>
<div>
    <div class="show-menu-container d-md-none" @click="clickButton" >
        <button data-toggle="collapse"
        data-target="#link-list-collapse"
        aria-expanded="false"
        aria-controls="link-list-collapse"
        ref="btnContainer"
        class="btn btn-none btn-show-menu">
          <span>{{activeSlug}}</span>
          <span class="arrow-down-end"></span>
        </button>
    </div>
    <ul v-if="links.length !== 0" class="link-list collapse" id="link-list-collapse">
      <li v-for="link in links" :key="link.text">
        <router-link
          class="bcm-link bcm-link-secondary"
          :to="link.to"
          :title="link.to"
        >
          {{link.text}}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  data: () => ({
    activeSlug: '',
    links: []
  }),
  methods: {
    clickButton () {
      $('.btn-show-menu').click()
    },
    async generateLinks () {
      const _this = this
      let $elem
      let links = []
      let find = $('.column-section .footer-list').eq(1).find('li:not(".d-none") a')
      if (find.length === 0) {
        setTimeout(this.generateLinks.bind(this), 500)
        return false
      }
      find.each((index, elem) => {
        $elem = $(elem)
        links.push({
          text: $elem.text(),
          to: $elem.attr('href')
        })
        if (location.href.includes($elem.attr('href'))) {
          _this.activeSlug = $elem.text()
          // update title
          _this.$emit('update:title', _this.activeSlug)
        }
      })
      this.links = links
    }
  },
  mounted () {
    this.generateLinks()
  }
}
</script>
<style lang="scss" scoped>
ul{
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    margin-bottom: 15px;
  }
}
</style>
